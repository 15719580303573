import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';
import { SignupLink } from 'components/Links';

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0;
  }
`;

export const SmallPrice = styled.span`
  font-weight: ${props => props.theme.fontWeight.normal};
  font-size: ${props => props.theme.fontSize.md};
  margin-right: ${getDimension('size1')};
`;

export const StyledPrice = styled.h2`
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: ${props => props.theme.fontSize.xxl};
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: ${props => props.theme.fontSize.xxxs};
`;

export const PriceNote = styled.p`
  margin: 0;
  margin-left: ${getDimension('size1')};
  font-size: ${props => props.theme.fontSize.xxxs};
  line-height: ${props => props.theme.fontSize.xxs};
  align-self: flex-start;
`;

export const StyledSignupLink = styled(SignupLink)`
  padding-left: ${getDimension('size10')};
  padding-right: ${getDimension('size10')};
`;

export const FooterNote = styled.p`
  font-size: ${props => props.theme.fontSize.xxxs};
`;
