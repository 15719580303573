const SVGHero = () => (
  <svg
    width="464"
    height="219"
    viewBox="0 0 464 219"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Sharesight Pro for Accountants</title>
    <rect
      x="0.142578"
      y="165.77"
      width="55.3384"
      height="13.6875"
      fill="url(#paint0_linear_2947_26453)"
    />
    <rect x="0.142578" y="155.125" width="55.3384" height="10.6458" fill="#202EAE" />
    <rect x="0.142578" y="89.7297" width="55.3384" height="65.3957" fill="#808BF3" />
    <rect
      x="67.2773"
      y="152.083"
      width="55.3384"
      height="15.2083"
      fill="url(#paint1_linear_2947_26453)"
    />
    <rect x="67.2773" y="145.999" width="55.3384" height="6.08332" fill="#202EAE" />
    <rect x="67.2773" y="112.541" width="55.3384" height="33.4583" fill="#808BF3" />
    <rect
      x="134.408"
      y="165.771"
      width="55.3384"
      height="53.2291"
      fill="url(#paint2_linear_2947_26453)"
    />
    <rect x="134.408" y="146" width="55.3384" height="19.7708" fill="#202EAE" />
    <rect x="134.408" width="55.3384" height="146" fill="#808BF3" />
    <rect
      x="201.539"
      y="165.77"
      width="55.3384"
      height="27.3749"
      fill="url(#paint3_linear_2947_26453)"
    />
    <rect x="201.539" y="155.124" width="55.3384" height="10.6458" fill="#202EAE" />
    <rect x="201.539" y="21.2915" width="55.3384" height="133.833" fill="#808BF3" />
    <path
      d="M457.28 112.541C457.28 157.249 421.037 193.492 376.329 193.492C331.622 193.492 295.379 157.249 295.379 112.541C295.379 67.8338 331.622 31.5911 376.329 31.5911C421.037 31.5911 457.28 67.8338 457.28 112.541Z"
      fill="#FC6B1A"
    />
    <path
      d="M456.293 125.14C459.043 107.686 456.007 89.8106 447.647 74.2433C439.287 58.6761 426.063 46.2716 409.994 38.9233C393.925 31.5749 375.892 29.6862 358.649 33.5454C341.406 37.4047 325.899 46.7999 314.495 60.2971L376.329 112.541L456.293 125.14Z"
      fill="#9C077B"
    />
    <path
      d="M305.103 151.009C311.902 163.598 321.915 174.16 334.124 181.619C346.333 189.078 360.301 193.169 374.605 193.473C388.909 193.778 403.038 190.286 415.554 183.354C428.069 176.421 438.524 166.295 445.853 154.008C453.181 141.72 457.123 127.71 457.275 113.403C457.427 99.0967 453.785 85.0055 446.72 72.5646C439.654 60.1237 429.417 49.7776 417.052 42.5802C404.687 35.3827 390.636 31.5909 376.328 31.5911L376.329 112.541L305.103 151.009Z"
      fill="url(#paint4_linear_2947_26453)"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle
        cx="376.329"
        cy="112.541"
        r="81.0244"
        transform="rotate(-4.1716 376.329 112.541)"
        fill="url(#paint5_radial_2947_26453)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2947_26453"
        x1="110.205"
        y1="172.614"
        x2="0.142572"
        y2="172.614"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2947_26453"
        x1="177.339"
        y1="159.687"
        x2="67.2773"
        y2="159.687"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2947_26453"
        x1="244.47"
        y1="192.386"
        x2="134.408"
        y2="192.386"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2947_26453"
        x1="311.601"
        y1="179.458"
        x2="201.539"
        y2="179.458"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2947_26453"
        x1="343.326"
        y1="193.492"
        x2="462.003"
        y2="161.263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF823C" />
        <stop offset="1" stopColor="#FEC2A0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2947_26453"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(422.362 115.899) rotate(-171.354) scale(128.727 103.482)"
      >
        <stop offset="0.453125" stopColor="#FFE6A5" stopOpacity="0" />
        <stop offset="1" stopColor="#F5C54C" />
      </radialGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
