import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Container, Section } from 'components/Container';
import { ProPricingCards } from 'components/ProPricingCards/ProPricingCards';
import { getTaxType } from 'helpers/getTaxType';

export const ProPricingBlock = () => {
  const {
    priceIncludesTax,
    translations: { currencyCode, currencySymbol },
  } = useContentfulLocale();

  const currency = currencySymbol || '$';
  const taxType = getTaxType(currencyCode);

  return (
    <Container id="pricing">
      <Section>
        <h2>Simple pricing, no commitment</h2>
        <p>
          Whether you need to track 5 portfolios or 500, you never pay more than {currency}
          13.50 per portfolio, per month. And the more portfolios you set up, the more affordable it
          gets.
        </p>
        <p>
          With Sharesight, there are no hidden fees, no extras that you have to pay for and no
          contracts. Just one simple price. And you can put us to the test with a free{' '}
          <strong>30-day no-risk trial</strong> – no credit card required.
        </p>
        <ProPricingCards currencySymbol={currency} />
        Prices in {currencyCode}
        {!priceIncludesTax ? `. Taxes may apply` : ` excluding ${taxType}`}.
      </Section>
    </Container>
  );
};
