import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { proPricingPlans } from 'page-data/pricing';

import { Box } from 'components/Box';
import { AccentCard, AccentCardsContainer } from 'components/AccentCards';

import { Price } from './Price';

import type { Translations } from 'helpers/mergeTranslations';
import type { LocaleProPlan, ProPlan } from 'page-data/pricing';

const proPlanOptions: ProPlan[] = ['5-19', '20-49', '50-99', '100+'];

export const ProPricingCards = ({
  currencySymbol,
}: {
  currencySymbol: Translations['currencySymbol'];
}) => {
  const {
    currentLocale: { id },
  } = useLocalizationContext();

  // Get localized pricing plans
  const localizedPlans: LocaleProPlan = proPricingPlans[id];

  return (
    <Box paddingTop={10} paddingBottom={8}>
      <AccentCardsContainer maxColumns={4}>
        {proPlanOptions.map(plan => (
          <AccentCard
            key={plan}
            title={`${plan} Portfolios`}
            text={<Price price={localizedPlans[plan].price} currencySymbol={currencySymbol} />}
          />
        ))}
      </AccentCardsContainer>
    </Box>
  );
};
