import { Box } from 'components/Box';
import {
  PriceContainer,
  Subtitle,
  SmallPrice,
  StyledPrice,
} from 'components/PricingCards/PricingDetail.styled';

import type { FC } from 'react';
import type { Translations } from 'helpers/mergeTranslations';

export const Price: FC<{ price: number; currencySymbol?: Translations['currencySymbol'] }> = ({
  price,
  currencySymbol,
}) => {
  const integerPart = Math.floor(price);
  const decimalPart = Math.round((price % 1) * 100);
  const currency = currencySymbol || '$';

  return (
    <Box marginBottom={1}>
      <PriceContainer>
        <StyledPrice>
          {currency}
          {integerPart}
          {!!Number(decimalPart) && decimalPart < 100 && <SmallPrice>.{decimalPart}</SmallPrice>}
        </StyledPrice>

        <Subtitle>
          Per portfolio/
          <br />
          month
        </Subtitle>
      </PriceContainer>
    </Box>
  );
};
