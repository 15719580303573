import { useMemo } from 'react';

import { useContentfulPage } from 'hooks/useContentfulPage';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { PartnerLink, SignupLink } from 'components/Links';

import {
  Container,
  GridContainer,
  HeroContainer,
  Section,
  SectionImage,
} from 'components/Container';

import { Cards } from 'components/Cards';
import { Notice } from 'components/Notice';
import { ProPricingBlock } from 'components/ProPricingBlock';

import HeroImage from 'images/accountants/hero';

import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { FC } from 'react';

export const Accountants: FC<LocalizedPageProps> = () => {
  const { cards } = useContentfulPage();

  const proAccountantsCards = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('pro-accountants-card-')),
    [cards]
  );

  const featuresCards = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('features-accountants-card-')),
    [cards]
  );

  return (
    <Layout>
      <Seo />

      <HeroContainer background="navyBlue" skipColoration>
        <Section>
          <h1>Sharesight Pro for accountants</h1>
          <p>
            A portfolio tracker that saves time and money, for you, and your clients. Automate your
            practice with a 30-day free trial. No credit card needed.
          </p>

          <br />
          <br />

          <SignupLink asButton containerBackground="negative" />
        </Section>
        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>
      <Cards
        header="Reduce admin and boost efficiency"
        subHeader="Sharesight Pro combines all the information accountants need to efficiently manage clients
          investment portfolios at tax time. Tax reporting and asset management tools automate
          transactional processing and save you hours of manual administration, while comprehensive
          performance reports provide the insights you need to deepen client relationships."
        cards={proAccountantsCards}
      />
      <Notice
        background="callout"
        header="Sharesight Pro makes managing client portfolios a breeze"
        button={<SignupLink asButton />}
      />
      <Container>
        <Section>
          <h2>Seamless integration with Xero</h2>{' '}
          <p>
            Synchronise your clients’ Sharesight portfolio data with{' '}
            <PartnerLink to="partners/xero" title="Xero | Sharesight">
              Xero
            </PartnerLink>
            , to automatically import details of share purchases, sales, and dividends in real-time.
            This provides you complete visibility of investment holdings, and allows you to easily
            reconcile portfolio data against bank statements – without the frustrating paper-chase.
            Your clients also benefit from Xero data being displayed in Sharesight, with bank
            balances, term deposits, and cash investments, providing a complete view of their
            financial position.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'accountants/xero',
            alt: 'Seamless integration with Xero',
          })}
          boxShadow={false}
        />
      </Container>
      <Cards
        header="Powerful Pro features that change the way you work"
        subHeader="Keeping track of your client’s share portfolios used to be a hassle. Sharesight Pro makes it simple. Want automatic trade recording with comprehensive performance reporting, wrapped up in an easy-to-use, fully online system? That’s Sharesight Pro in a nutshell."
        cards={featuresCards}
      />
      <ProPricingBlock />
      <Container>
        <Section>
          <h2>Sharesight Pro makes managing client portfolios a breeze</h2>

          <p>
            <strong>Easy sign-up</strong>
            <br />
            No billing details or credit card required
          </p>
          <br />
          <p>
            <strong>No hidden fees or contracts</strong>
            <br />
            Upgrade, downgrade or cancel anytime
          </p>
          <br />
          <SignupLink asButton />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'accountants/laptop',
            alt: 'Laptop with Sharesight product',
          })}
          boxShadow={false}
        />
      </Container>
      <Container>
        <Section>
          <h2>
            Accountants love <br />
            Sharesight Pro
          </h2>
          <GridContainer columns={3} verticalAlign="top">
            <div>
              <h3>Chris Wheatley</h3>
              <p>Scope Accounting</p>
              <p>
                “In terms of productivity, it definitely gives us a faster turnaround time and job
                throughput, because what could take 3 hours in Excel takes 20 minutes in
                Sharesight.”
              </p>
            </div>
            <div>
              <h3>Taryn Port</h3>
              <p>Sharesight Pro Customer</p>
              <p>
                “Sharesight and Xero are a great combination for clients with investment portfolios.
                Once set up the integration works really well with income flowing from the portfolio
                through sharesight to Xero. Will save a lot of time in the second year and onwards.”
              </p>
            </div>
            <div>
              <h3>Philippa O’Mara</h3>
              <p>Engine Room Chartered Accountants</p>
              <p>
                “Sharesight saves us 2-3 hours on a mid-sized portfolio, simply removing the need to
                track down missing dividend statements and create the schedule of income, as well as
                the calculations involved for foreign shares.”
              </p>
            </div>
          </GridContainer>
        </Section>
      </Container>
    </Layout>
  );
};

export default Accountants;
