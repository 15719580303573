import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const AccentCardsContainer = styled.div<{ maxColumns: 2 | 4 }>`
  display: flex;
  flex-wrap: wrap;

  gap: ${getDimension('size2')};

  // wrapping behaviour: 4 columns -> 2 columns -> 1 column
  > * {
    flex-basis: ${props => (props.maxColumns === 4 ? '20%' : '45%')};

    ${({ theme }) => theme.breakpoints.down('lg')} {
      flex-basis: 45%;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      flex-basis: 100%;
    }
  }
`;
